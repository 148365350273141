<ion-header buscador>
  <ion-navbar>
    <button ion-button clear no-shadow (click)="close_page()" name="md-arrow-back" style="margin: 0px 10px !important;">
      < </button>
        <ion-title>PRODUCTOS</ion-title>
        <ion-card-subtitle>ADMINISTRACIÓN</ion-card-subtitle>
        <ion-icon class="title-head" src="assets/images/icon-buffet-admin.svg"></ion-icon>
  </ion-navbar>
</ion-header>

<ion-content *ngIf="!crearProductosModal" buscador border-radius style="top: 5%;">
  <ion-grid id="cuentas-ya-listadas">
    <ion-row class="listado-clientes-header" style="background-color: #F1F1F1F1;">
      <h2 class="ion-text-start globe-cat-to-filter" [ngClass]="{'selected' : filter_product }"
        (click)="filterTableBy(4)">Producto</h2>
      <h2 class="cuenta globe-cat-to-filter" [ngClass]="{'selected' : filter_proveedor }" (click)="filterTableBy(1)">
        Proveedor </h2>
      <h2 class="globe-cat-to-filter" [ngClass]="{'selected' : filter_group }" (click)="filterTableBy(2)">Grupo</h2>
      <h2 class="globe-cat-to-filter" [ngClass]="{'selected' : filter_subgroup }" (click)="filterTableBy(3)">Subgrupo
      </h2>
    </ion-row>
  </ion-grid>

  <div>

    <ion-accordion-group>
      <ion-accordion value="first" *ngFor="let p of productosLista" id="{{p.id + '_producto'}}"
        class="resultadoCliente fila-de-lista-clientes">
        <ion-item slot="header" color="light" (click)="openAccordionProduct(p.id)">
          <ion-label *ngIf="p.es_favorito == 1"
            [ngClass]="{'product-is-fav' : p.es_favorito == 1 }">{{p.nombre.toUpperCase()}} <ion-icon
              src="/assets/images/icon-star-full.svg" class="fav-icon-table"></ion-icon> <button ion-button clear
              no-shadow name="md-arrow-acordion" class="accordion-button"
              [ngClass]="{'accordion_is_open' : accordionOpen == p.id}">
              >
            </button></ion-label>
          <ion-label *ngIf="p.es_favorito != 1">{{p.nombre.toUpperCase()}} <button ion-button clear no-shadow
              name="md-arrow-acordion" class="accordion-button"
              [ngClass]="{'accordion_is_open' : accordionOpen == p.id}">
              >
            </button></ion-label>
        </ion-item>
        <div [ngClass]="{'accordionOpen' : accordionOpen == p.id}" style="display: none;" class="ion-padding"
          slot="content">
          <ul style="margin: 0px;">
            <ion-row>
              <li><a (click)="goToProducto(p)">Ver detalle</a></li>
            </ion-row>
            <ion-row>
              <li>Proveedor: {{p.empresa_nombre}}</li>
            </ion-row>
            <ion-row>
              <li *ngIf="p.deleted_grupo" title="Eliminado" [ngClass]="{'group-deleted' : p.deleted_grupo }">Grupo
                eliminado: {{p.grupo_nombre.toUpperCase()}}</li>
              <li *ngIf="!p.deleted_grupo" [ngClass]="{'group-deleted' : p.deleted_subgrupo }">Grupo:
                {{p.grupo_nombre.toUpperCase()}}</li>
            </ion-row>
            <ion-row>
              <li *ngIf="p.deleted_subgrupo" title="Eliminado" [ngClass]="{'group-deleted' : p.deleted_subgrupo }">Sub
                Grupo eliminado: {{p.subgrupo_nombre.toUpperCase()}}</li>
              <li *ngIf="!p.deleted_subgrupo" [ngClass]="{'group-deleted' : p.deleted_subgrupo }">Sub Grupo:
                {{p.subgrupo_nombre.toUpperCase()}}</li>
            </ion-row>
            <ion-row>
              <li>Tipo: {{p.cod_tipo_producto == 100 ? 'Producto' : 'Insumo'}}</li>
            </ion-row>
          </ul>
        </div>
      </ion-accordion>
    </ion-accordion-group>

    <!-- <ion-col size="2" class="border-right-row">
              <h3 class="grey-medium" id="resultado.created_at">{{p.created_at}}</h3>
            </ion-col>
            <ion-col size="3" class="border-right-row">
              <h3 class="grey-medium" id="resultado.nombre">{{p.nombre}}</h3>
            </ion-col>
            <ion-col size="4" class="border-right-row">
              <h3 class="grey-medium" id="resultado.nombreDelCliente">{{p.nombreDelCliente}}</h3>
            </ion-col>
            <ion-col size="2">
              <h3 class="grey-medium" id="resultado.total">${{p.total}}</h3>
            </ion-col> -->
    <!-- </ion-row> -->
    <ion-row class="">
      <ion-col size="2" id="las-col-white-1" class="las-col-white border-right-row complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
      <ion-col size="3" id="las-col-white-2" class="las-col-white border-right-row complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
      <ion-col size="4" id="las-col-white-3" class="las-col-white border-right-row complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
      <ion-col size="2" id="las-col-white-4" class="las-col-white complete-size-row">
        <h3 class="grey-medium" id=""></h3>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
<div id="float-search">
  <ion-searchbar (ionChange)="filtrarProductosMobile()" [(ngModel)]="palabraBuscador" (click)="closeSearchBar()"
    class="input-search" color="dark" placeholder="{{search_placeholder}}"></ion-searchbar>
</div>
<!-- <ion-fab *ngIf="search_float_button" style="bottom: 5em; right: 1.5em;" (click)="openSearchBar()">
  <ion-fab-button color="dark" style="padding: 0.4em;" >
    <ion-icon name="search"></ion-icon>
  </ion-fab-button>
</ion-fab> -->
<ion-fab style="bottom: 2em;">
  <ion-fab-button *ngIf="!crearProductosModal" (click)="mostrarModal()" style="padding: 5%;
  --background: #13c360;
  width: 13em;
  --border-radius: 1.5em;">
    <ion-icon style="margin-right: 2%;" name="add-circle-outline"></ion-icon>Crear Producto
  </ion-fab-button>
</ion-fab>
<ion-content *ngIf="crearProductosModal" buscador border-radius style="top: 5%;">
  <div *ngIf="crearProductosModal" class="crear-productos">
    <div class="header">
      <h2 id="chartTitleMobil"
        [ngClass]="{'colorHC' : crearProductosTitle == 'Historial de Costo', 'colorHP' : crearProductosTitle == 'Historial de Precio'}">
        {{crearProductosTitle}}</h2>
      <button btn-back (click)="closedProductInfo()" class="closedButton">x</button>
    </div>
    <div *ngIf="productosModalContainer" class="crear-productos-content">
      <div class="container-imagen-poductos">
        <figure [ngClass]="{'isEmpty' : (producto.imagen == null) }"><img *ngIf="(producto.imagen != null)"
            src="{{productoURL}}{{producto.imagen}}" alt="Producto">
          <button (click)="openMenuCamera()">
            <ion-icon></ion-icon>
          </button>
        </figure>
      </div>
      <div class="container-productos-origen">
        <ion-item lines="none">
          <button class="changeToText" (click)="cambiarATexto(1)"></button>
          <ion-label style="display: none;" hide>Seleccione un proveedor</ion-label>
          <ion-input id="fieldProvedor2" class="inputText-1 font-mays" type="text" required
            [(ngModel)]="producto.empresa_producto_nombre"></ion-input>
          <div id="fieldProvedor" class="select-search inputSelect-1 font-mays"
            (click)="openOptionsModal(empresasLista,'Empresa')">{{producto.empresa_nombre}}</div>
          <ion-icon class="search-icon-edit-product" style="background-color: #13c35f00;"
            name="search-outline"></ion-icon>

        </ion-item>
        <ion-item lines="none">
          <button class="changeToText" (click)="cambiarATexto(2)"></button>
          <ion-label style="display: none;" hide>Seleccione un grupo</ion-label>
          <ion-input id="grupoProductoField2" class="inputText-2 font-mays" type="text" required
            [(ngModel)]="producto.grupo_producto_nombre"></ion-input>
          <div id="grupoProductoField" class="select-search inputSelect-2 font-mays"
            (click)="openOptionsModal(grupoLista,'Grupo')">{{producto.grupo_nombre}}</div>
          <ion-icon class="search-icon-edit-product" style="background-color: #13c35f00;"
            name="search-outline"></ion-icon>
        </ion-item>
        <ion-item lines="none">
          <button class="changeToText" (click)="cambiarATexto(3)"></button>
          <ion-label style="display: none;" hide>Seleccione un subgrupo</ion-label>
          <ion-input id="subgrupoProductoField2" class="inputText-3 font-mays" type="text" required
            [(ngModel)]="producto.subgrupo_producto_nombre"></ion-input>
          <div id="subgrupoProductoField" class="select-search inputSelect-3 font-mays"
            (click)="openOptionsModal(grupoListaP,'Subgrupo')">{{producto.subgrupo_nombre}}</div>
          <ion-icon class="search-icon-edit-product" style="background-color: #13c35f00;"
            name="search-outline"></ion-icon>
        </ion-item>
        <ion-item lines="none" class="only-text">
          <ion-icon></ion-icon>
          <ion-input id="nombreField" class="select-search font-mays" placeholder="Nombre del producto"
            [(ngModel)]="producto.nombre" type="text" required></ion-input>
          <ion-icon *ngIf="producto.es_favorito==1" src="/assets/images/icon-star-full.svg"
            class="search-icon-edit-product fav-icon" (click)="changeIsFavorrite()"
            title="Producto favorito"></ion-icon>
          <ion-icon *ngIf="producto.es_favorito!=1" src="/assets/images/icon-star.svg"
            class="search-icon-edit-product fav-icon" (click)="changeIsFavorrite()"
            title="Producto favorito"></ion-icon>
        </ion-item>
        <ion-item lines="none" class="only-text">
          <ion-icon></ion-icon>

          <ion-input id="codInternoField" class="select-search font-mays" placeholder="Código interno"
            [(ngModel)]="producto.codigo_interno" type="text" required></ion-input>

        </ion-item>
        <ion-item lines="none" class="only-text">
          <ion-icon></ion-icon>
          <ion-input id="codBarrasField" class="select-search" placeholder="Código de barras"
            [(ngModel)]="producto.codigo_barras" type="text" required></ion-input>
        </ion-item>

        <ion-item class="noHover" *ngIf="producto.id">
          <ion-icon></ion-icon>
          <ion-select interface="popover" readonly="" class="font-mays select-search select-style2"
            placeholder="TIPO DE PRODUCTO" [(ngModel)]="producto.cod_tipo_producto">
            <ion-select-option class="font-mays select-search" *ngFor="let tipo of tiposProductos" [value]="tipo.id">{{
              tipo.name }}</ion-select-option>
          </ion-select>
        </ion-item>

      </div>
      <div class="contador-inventario">
        <ion-item lines="none" class="perc">
          <ion-label>IVA</ion-label>
          <ion-input id="ivaField" [(ngModel)]="producto.iva" type="number"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label>Costo&nbsp;<span style="display: inline;font-size: 10px;">(IVA incl.)</span></ion-label>
          <ion-input id="costoField" [(ngModel)]="producto.precio_costo_actual" type="number"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label>P. venta&nbsp;<span style="display: inline;font-size: 10px;">(IVA incl.)</span></ion-label>
          <ion-input id="ventaField" [(ngModel)]="producto.precio_venta_actual" type="number"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label>Stock mínimo</ion-label>
          <ion-input id="stockField" [(ngModel)]="producto.stock_minimo" type="number">
            <div></div>
          </ion-input>
        </ion-item>
        <ion-item *ngIf="producto.id == null || producto.id == undefined" lines="none">
          <ion-label>Stock inicial</ion-label>
          <ion-input id="stockActual" [(ngModel)]="producto.stock_actual" type="number"></ion-input>
        </ion-item>
        <ion-item *ngIf="(producto.id != null && producto.id != undefined) && obtenerPermiso('buffet.editar')"
          lines="none">
          <ion-label>Stock actual</ion-label>
          <ion-input id="stockActual" [(ngModel)]="producto.stock_actual" type="number"></ion-input>
        </ion-item>
      </div>
      <div class="header historialButtonsContainers">
        <ion-item *ngIf="(crearProductosTitle == 'Editar Producto')" class="historialButtons"
          (click)="historialModal(1)">
          <button class="hbCosto">HISTORIAL DE COSTO</button>
        </ion-item>
        <ion-item *ngIf="(crearProductosTitle == 'Editar Producto')" class="historialButtons hbPrecio"
          (click)="historialModal(2)">
          <button>HISTORIAL DE PRECIO</button>
        </ion-item>
      </div>
    </div>
    <div *ngIf="historialProductosModal" class="historial-productos-content" style="margin-top: 0px;">
      <div *ngIf="historialProductosCostoModal" class="container-historial-costo">
        <div class="container-historial-costo-grafico" style="width: auto; padding-left: 0px; margin-left: 5px;">
          <canvas id="barChartHistory"
            style="height: 330px; width: 330px; max-height: 330px; max-width: 330px;"></canvas>
        </div>
        <div class="changeChartTime">
          <div (click)="changeChartTieFilter('MES',1,32)"
            [ngClass]="{'timeChartSelectedMes': chartFilterSelect == 'MES' }">MES</div>
          <div (click)="changeChartTieFilter('SEMESTRE',1,184)"
            [ngClass]="{'timeChartSelectedSemester': chartFilterSelect == 'SEMESTRE' }">SEMESTRE</div>
          <div (click)="changeChartTieFilter('AÑO',1,366)"
            [ngClass]="{'timeChartSelectedYear': chartFilterSelect == 'AÑO' }">AÑO</div>
        </div>
      </div>
      <div *ngIf="historialProductosPrecioModal" class="container-historial-precio">
        <div class="container-historial-precio-grafico" style="width: auto; padding-left: 0px; margin-left: 5px;">
          <canvas id="barChartHistory" style="height: 330px; width: 330px;"></canvas>
        </div>
        <div class="changeChartTime">
          <div (click)="changeChartTieFilter('MES',2,32)"
            [ngClass]="{'timeChartSelectedMes': chartFilterSelect == 'MES' }">MES</div>
          <div (click)="changeChartTieFilter('SEMESTRE',2,184)"
            [ngClass]="{'timeChartSelectedSemester': chartFilterSelect == 'SEMESTRE' }">SEMESTRE</div>
          <div (click)="changeChartTieFilter('AÑO',2,366)"
            [ngClass]="{'timeChartSelectedYear': chartFilterSelect == 'AÑO' }">AÑO</div>
        </div>
      </div>
      <div class="header historialButtonsContainers otherGraficButton">
        <ion-item *ngIf="historialProductosPrecioModal" class="historialButtons" (click)="historialModal(1)"
          [ngClass]="{'selected' : historialProductosCostoModal }">
          <button class="hbCosto">HISTORIAL DE COSTO</button>
        </ion-item>
        <ion-item *ngIf="historialProductosPrecioModal" style="visibility: hidden;" class="historialButtons hbPrecio"
          (click)="historialModal(2)" [ngClass]="{'selected' : historialProductosPrecioModal }">
          <button>.</button>
        </ion-item>
        <ion-item *ngIf="historialProductosCostoModal" class="historialButtons hbPrecio" (click)="historialModal(2)"
          [ngClass]="{'selected' : historialProductosPrecioModal }">
          <button>HISTORIAL DE PRECIO</button>
        </ion-item>
      </div>
    </div>
  </div>
</ion-content>



<div class="dialog-float dialog-search" *ngIf="modalSelecSearchOptions">
  <div class="content-white" style="width: 80%;margin:0 auto;">
    <div class="content-padding">
      <h3>
        <i class="ion-md-funnel-2-green"></i> Elegir {{placeholder_select_option_search}}
      </h3>

      <p>
        Busca el {{placeholder_select_option_search}} deseado:
      </p>
    </div>

    <div class="content-padding">
      <div class="content-search" style="margin-bottom: 15%;">
        <div class="search-input">
          <ion-searchbar placeholder="Buscar por palabra..." [(ngModel)]="palabraBuscadorSelect"
            (ionChange)="buscadorSelect()">
          </ion-searchbar>
        </div>

        <div class="content-results">
          <!-- <h3>
            <i></i> CLASES
          </h3> -->
          <ul class="list-results">
            <li class="selected" *ngFor="let f of filtro_opciones"
              [ngClass]="{'selected': actualOptionSelected(placeholder_select_option_search,f.id)}"
              (click)="selectGroup(f.id,f.nombre)">
              <i></i> {{ f.nombre }} <ion-icon
                *ngIf="!actualOptionSelected(placeholder_select_option_search,f.id) && f.nombre != 'BEBIDAS' && f.nombre != 'ALIMENTOS' && f.nombre != 'PRODUCTOS'"
                (click)="editGroup(f)" class="edit-icon"
                src="/assets/images/icon-pencil-edit-black.svg"></ion-icon><ion-icon
                *ngIf="!actualOptionSelected(placeholder_select_option_search,f.id) && f.nombre != 'BEBIDAS' && f.nombre != 'ALIMENTOS' && f.nombre != 'PRODUCTOS'"
                (click)="deleteGroup(f)" class="delete-icon" src="/assets/images/icon-trash-red.svg"></ion-icon>
            </li>
          </ul>
        </div>
      </div>

      <div class="select-buttons-modal">
        <div (click)="selectOptionSearch(false)" size="3" class="btn-grey-outline"
          style="margin-right: 2%!important; line-height: unset">
          CANCELAR
        </div>
        <div (click)="selectOptionSearch(true)" size="3" class="btn-blue"
          style="margin-right: 2%!important; margin-left: 0%!important;">
          <i class="ion-md-funnel-2"></i> SELECCIONAR
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dialog-float dialog-search" *ngIf="modalSelecSearchOptionsCompany">
  <div class="content-white" style="width: 80%;margin:0 auto;">
    <div class="content-padding">
      <h3>
        <i class="ion-md-funnel-2-green"></i> Elegir Proveedor
      </h3>

      <p>
        Busca el Proveedor deseado:
      </p>
    </div>

    <div class="content-padding">
      <div class="content-search" style="margin-bottom: 15%;">
        <div class="search-input">
          <ion-searchbar placeholder="Buscar por palabra..." [(ngModel)]="palabraBuscadorSelectCompany"
            (ionChange)="buscadorSelectCompany()">
          </ion-searchbar>
        </div>

        <div class="content-results">
          <!-- <h3>
            <i></i> CLASES
          </h3> -->
          <ul class="list-results">
            <li class="selected" *ngFor="let f of filtro_opciones_company"
              [ngClass]="{'selected': actualOptionSelectedCompany(placeholder_select_option_search,f.id)}"
              (click)="selectGroupCompany(f.id,f.nombre)">
              <i></i> {{ f.nombre }} <ion-icon
                *ngIf="!actualOptionSelectedCompany(placeholder_select_option_search,f.id)" (click)="editProveedor(f)"
                class="edit-icon" src="/assets/images/icon-pencil-edit-black.svg"></ion-icon><ion-icon
                *ngIf="!actualOptionSelectedCompany(placeholder_select_option_search,f.id)" (click)="deleteProveedor(f)"
                class="delete-icon" src="/assets/images/icon-trash-red.svg"></ion-icon>
            </li>
          </ul>
        </div>
      </div>

      <div class="select-buttons-modal">
        <div (click)="selectOptionSearchCompany(false)" size="3" class="btn-grey-outline"
          style="margin-right: 2%!important; line-height: unset">
          CANCELAR
        </div>
        <div (click)="selectOptionSearchCompany(true)" size="3" class="btn-blue"
          style="margin-right: 2%!important; margin-left: 0%!important;">
          <i class="ion-md-funnel-2"></i> SELECCIONAR
        </div>
      </div>
    </div>
  </div>
</div>

<ion-fab style="bottom: 5em; left: 27.5vW;">
  <ion-fab-button *ngIf="crearProductosModal && !historialProductosModal && oneCheckButton"
    (click)="guardarInfoProducto()" style="padding: 5%;
  --background: #13c360;
  width: 13em;
  --border-radius: 1.5em;">
    <!-- <ion-icon style="margin-right: 2%;" name="add-circle-outline" ></ion-icon> -->
    GUARDAR
  </ion-fab-button>
</ion-fab>

<ion-fab style="bottom: 2em; left: 27.5vW;">
  <ion-fab-button *ngIf="crearProductosModal && (crearProductosTitle == 'Editar Producto')"
    (click)="openEliminar(producto, 1)" style="padding: 5%;
  --background: #E9151E;
  width: 13em;
  --border-radius: 1.5em;">
    ELIMINAR PRODUCTO
  </ion-fab-button>
</ion-fab>