<!-- CALCULADORA -->
<div class="float-content-calculadora" [hidden]="!openedCalculadora">
  <div class="content-calculadora ind">
    <div class="calculadora-padding">
      <div class="text-cobrar selected">
        <div class="box">
          <ion-icon
            src="/assets/images/calculadora/icon-a-cobrar.svg"
          ></ion-icon>
          <span>SALDO A COBRAR</span>
        </div>
        <span class="monto">${{ calc_a_cobrar }}</span>
      </div>

      <div
        class="text-monto efectivo"
        [ngClass]="{ selected: pago.tipo_pago === 1 }"
        (click)="calcBtn(13)"
        (keydown)="onKeyDown($event)"
        tabindex="0"
        data-tipo-pago-id="1"
        #divEfectivo
      >
        <div class="box">
          <ion-icon
            *ngIf="pago.tipo_pago !== 1"
            src="/assets/images/calculadora/icon-efectivo.svg"
          ></ion-icon>
          <ion-icon
            *ngIf="pago.tipo_pago === 1"
            src="/assets/images/calculadora/icon-efectivo-white.svg"
          ></ion-icon>
          <div class="box-text-monto">
            <small>MONTO</small>
            <span>EFECTIVO</span>
          </div>
        </div>

        <span class="monto">${{ calc_monto_efectivo }}</span>
      </div>

      <div
        class="text-monto tarjeta"
        [ngClass]="{ selected: pago.tipo_pago === 2 }"
        (click)="calcBtn(14)"
        (keydown)="onKeyDown($event)"
        tabindex="1"
        data-tipo-pago-id="2"
      >
        <div class="box">
          <ion-icon
            *ngIf="pago.tipo_pago !== 2"
            src="/assets/images/calculadora/icon-tarjeta.svg"
          ></ion-icon>
          <ion-icon
            *ngIf="pago.tipo_pago === 2"
            src="/assets/images/calculadora/icon-tarjeta-white.svg"
          ></ion-icon>
          <div class="box-text-monto">
            <small>MONTO</small>
            <span>TARJETA</span>
          </div>
        </div>

        <span class="monto">${{ calc_monto_tarjeta }}</span>
      </div>

      <div
        class="text-monto transferencia"
        [ngClass]="{ selected: pago.tipo_pago === 3 }"
        (click)="calcBtn(15)"
        (keydown)="onKeyDown($event)"
        tabindex="2"
        data-tipo-pago-id="3"
      >
        <div class="box">
          <ion-icon
            *ngIf="pago.tipo_pago !== 3"
            src="/assets/images/calculadora/icon-transferencia.svg"
          ></ion-icon>
          <ion-icon
            *ngIf="pago.tipo_pago === 3"
            src="/assets/images/calculadora/icon-transferencia-white.svg"
          ></ion-icon>
          <div class="box-text-monto">
            <small>MONTO</small>
            <span>TRANSFERENCIA</span>
          </div>
        </div>

        <span class="monto">${{ calc_monto_transferencia }}</span>
      </div>

      <div
        *ngIf="calc_monto_a_favor > 0 && calc_a_cobrar > 0"
        class="text-monto a-favor"
        [ngClass]="{ selected: pago.tipo_pago === 4 }"
        (click)="calcBtn(16)"
        (keydown)="onKeyDown($event)"
        tabindex="3"
        data-tipo-pago-id="4"
      >
        <div class="box">
          <ion-icon
            *ngIf="pago.tipo_pago !== 4"
            src="/assets/images/calculadora/icon-dinero-a-favor.svg"
          ></ion-icon>
          <ion-icon
            *ngIf="pago.tipo_pago === 4"
            src="/assets/images/calculadora/icon-dinero-a-favor-white.svg"
          ></ion-icon>
          <div class="box-text-monto">
            <small>MONTO</small>
            <span>A FAVOR</span>
          </div>
        </div>

        <span class="monto">${{ calc_monto_a_favor }}</span>
      </div>

      <div
        class="text-monto mercado-pago"
        [ngClass]="{ selected: pago.tipo_pago === 5 }"
        (click)="calcBtn(17)"
        (keydown)="onKeyDown($event)"
        tabindex="4"
        data-tipo-pago-id="5"
      >
        <div class="box">
          <ion-icon
            *ngIf="pago.tipo_pago !== 5"
            src="/assets/images/calculadora/icon-mercado-pago.svg"
          ></ion-icon>
          <ion-icon
            *ngIf="pago.tipo_pago === 5"
            src="/assets/images/calculadora/icon-mercado-pago-white.svg"
          ></ion-icon>
          <div class="box-text-monto">
            <small>MONTO</small>
            <span>MERCADO PAGO</span>
          </div>
        </div>

        <span class="monto">${{ calc_monto_mp }}</span>
      </div>

      <p
        class="text-saldo"
        *ngIf="
          calc_monto_efectivo +
            calc_monto_tarjeta +
            calc_monto_transferencia +
            calc_monto_a_favor +
            calc_monto_mp -
            calc_a_cobrar <=
          0
        "
      >
        <i></i> SALDO TOTAL
        <span
          >${{
            calc_monto_efectivo +
              calc_monto_tarjeta +
              calc_monto_transferencia +
              calc_monto_a_favor +
              calc_monto_mp -
              calc_a_cobrar
          }}</span
        >
      </p>

      <p
        class="text-saldo"
        (click)="toggleCambioAFavor()"
        *ngIf="
          calc_monto_efectivo +
            calc_monto_tarjeta +
            calc_monto_transferencia +
            calc_monto_a_favor +
            calc_monto_mp -
            calc_a_cobrar >
          0
        "
      >
        <i [ngClass]="cambio ? 'cambio' : 'dejar-a-favor'"></i>
        {{ cambio ? "CAMBIO" : "DINERO A FAVOR" }}
        <span
          >${{
            calc_monto_efectivo +
              calc_monto_tarjeta +
              calc_monto_transferencia +
              calc_monto_a_favor +
              calc_monto_mp -
              calc_a_cobrar
          }}</span
        >
      </p>

      <div class="content-buttons-calc">
        <ul class="content-calc-numbers">
          <li>
            <a (click)="calcBtn(1)">
              <i class="icon-number-one"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(2)">
              <i class="icon-number-two"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(3)">
              <i class="icon-number-three"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(4)">
              <i class="icon-number-four"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(5)">
              <i class="icon-number-five"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(6)">
              <i class="icon-number-six"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(7)">
              <i class="icon-number-seven"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(8)">
              <i class="icon-number-eight"></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(9)">
              <i class="icon-number-nine"></i>
            </a>
          </li>
        </ul>
        <ul class="content-calc-actions">
          <li>
            <a class="btn-erase" (click)="calcBtn(10)">
              <i></i>
            </a>
          </li>
          <li>
            <a class="btn-ok" (click)="calcBtn(11)">
              <i></i>
            </a>
          </li>
          <li>
            <a (click)="calcBtn(0)">
              <i class="icon-number-zero"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="content-footer">
      <div class="content-buttons">
        <a (click)="closeCalculadora()" class="btn-cancelar-outline">
          CANCELAR
        </a>

        <a (click)="closeCalculadora(true)" class="btn-green"> CONTINUAR </a>
      </div>
    </div>
  </div>
</div>
